import React from "react"
import { Col, Container, Row } from "react-bootstrap"
import { graphql } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"
import Seo from "../components/seo"

export const query = graphql`
  query {
    fileName: file(relativePath: { eq: "over-mij-yvonne.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 675, maxHeight: 900) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

const AboutMe = ({ data }) => (
  <Layout pageInfo={{ pageName: "wie-ben-ik", pageTitle: "Wie ben ik?", bannerTitle: "website-header-02.png" }}>
    <Seo title="Wie ben ik?"/>
    <Container>
      <section className="general-page" id="about-us">
        <Container>
          <Row>
            <Col md={{ span: 8 }}>
              <div class="block">
                <p>Hallo allemaal!</p>

                <p>Ik ben Yvonne van Seters-Bogaard en ik heb Alles Kids Kindercoaching opgericht. Ik ben getrouwd met
                  Erik en samen hebben wij twee geweldige kinderen gekregen, Dex en Fenne.
                </p>

                <p>In 2008 ben ik bij de rechtbank Zeeland-West-Brabant in Middelburg gaan werken in het team Jeugd.
                  Jarenlang heb ik daar, samen met een kinderrechter, zaken behandeld met betrekking tot minderjarigen
                  die - om verschillende redenen - in hun ontwikkeling werden bedreigd. Door de jaren heen heb ik dan
                  ook veel geleerd over de ontwikkeling van kinderen en heb ik meegewerkt aan het tot stand komen van
                  uitspraken op basis waarvan de hulpverlening de invulling van het hulpverleningstraject verder vorm
                  kon geven.
                </p>

                <p>Door mijn werk op de rechtbank heb ik altijd het gevoel gehad een bijdrage te kunnen leveren aan de
                  hulpverlening aan kinderen. Tot ik met één van mijn kinderen, die een medische achtergrond heeft en
                  dus al best veel heeft meegemaakt, bij een kindercoach terecht kwam. Op dat moment realiseerde ik mij
                  dat ik liever zelf de persoon ben die met een kind aan de slag gaat dan op de achtergrond mee te
                  werken aan beslissingen die hem of haar raken. Ik heb besloten het roer volledig om te gooien en de
                  opleiding tot Kind- en Gezinscoach
                  te gaan volgen om daarna zelf als kind- en gezinscoach aan de slag te kunnen gaan. Tijdens en na mijn
                  opleiding heb ik de nodige werkervaring op mogen doen bij een grotere praktijk.
                  Daarnaast heb ik in 2021 mijn ervaringscertificaat Vakbekwame hbo jeugd- en gezinsprofessional
                  behaald, waarmee ik ben ingeschreven in het Stichting Kwaliteitsregister Jeugd
                  (SKJ).
                </p>

                <p>In mijn werk hoop ik kinderen en hun ouders of verzorgers op een praktische manier te kunnen helpen.
                  Ik denk dat ik hiervoor de aangewezen persoon ben, ook omdat ik als ouder in de schoenen heb gestaan
                  van de ouders en verzorgers die met hun kind bij mij zullen komen. Samen kunnen wij ervoor zorgen dat
                  uw kind zich weer fijn voelt en sterk in het leven staat!
                </p>

              </div>
            </Col>
            <Col md={{ span: 4 }} >
              <Img className="image-padding" fluid={data.fileName.childImageSharp.fluid} alt="Foto - Yvonne van Seters"/>
            </Col>
          </Row>
        </Container>
      </section>
    </Container>
  </Layout>
)

export default AboutMe
